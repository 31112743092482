<template>
  <v-card class="brk-info-window" elevation="4">
    <v-card-title>
      {{ productName }}
    </v-card-title>
    <v-card-subtitle>
      <v-row no-gutters>
        <v-col cols="auto">
          {{ `${$t("mapInfoWindow.machineSerialNo")} ${serialNo}` }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          {{ tenantName }}
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <div class="pb-4 product-image">
        <v-img max-width="150" :src="machineImageSrc" />
        <div class="d-flex align-center pb-0 info-chips">
          <v-chip label color="primary">
            {{ engineSeconds | engineHourFilter }}
          </v-chip>
          <v-chip label :class="healthColor">
            {{ status }}
          </v-chip>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="pr-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="grab-map-info-window-view mr-2"
        small
        @click="$emit('view', info)"
      >
        {{ $t("common.actions.view") }}
      </v-btn>
      <v-btn class="grab-map-info-window-close" small @click="$emit('close')">
        {{ $t("common.actions.close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Severity from "@/utils/severity"
import fetchClient from "@/utils/fetchClient"

export default {
  name: "InfoWindow",
  props: ["machine"],
  data() {
    return {
      info: null,
      error: null
    }
  },
  async created() {
    await this.fetchMachineDashboard()
  },
  methods: {
    async fetchMachineDashboard() {
      if (this.machine === undefined || this.machine === null) {
        return
      }

      const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/dashboard`

      try {
        const rsp = await fetchClient(this.$i18n).getDataOrThrow(url)
        this.info = rsp.machine
      } catch (error) {
        this.info = null
        console.error(error)
        this.error = { type: "error", ...error }
      }
    }
  },
  computed: {
    machineImageSrc() {
      if (this.info === null) {
        return "/products/jpg/default.jpg"
      }

      if (this.info.properties !== null && this.info.properties.tags !== null) {
        if (this.info.properties.tags.includes("smart_power_plus")) {
          return `/products/jpg/${this.info.productId}_SPP.jpg`
        }
      }
      return `/products/jpg/${this.info.productId}.jpg`
    },
    productName() {
      if (this.info?.properties === null) {
        return this.info?.productName
      }
      if (this.info?.properties !== null) {
        if (this.info?.properties.tags?.includes("smart_power_plus")) {
          return `${this.info?.productName}⁺`
        } else {
          return this.info?.productName
        }
      }
      return this.info?.productName
    },
    serialNo() {
      return this.info?.serialNo || ""
    },
    tenantName() {
      return this.info?.tenantName || ""
    },
    engineSeconds() {
      return this.info?.engineSeconds || 0
    },
    status() {
      return this.info?.status || ""
    },
    healthColor() {
      if (this.info !== null) {
        return Severity.color(this.info)
      } else {
        return "primary"
      }
    }
  },
  watch: {
    machine() {
      this.info = null
      this.error = null
      this.fetchMachineDashboard()
    }
  }
}
</script>

<style scoped>
.brk-info-window {
  min-width: 250px;
  margin-top: 30px; /* Depends on: this.div.style.transform inside googlemapsadaper.js*/
  pointer-events: all;
}

.brk-info-window::v-deep .product-image {
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  flex-wrap: wrap;
}
.info-chips {
  justify-content: space-around;
  align-self: stretch;
}
</style>
