/*global google*/

import Vue from "vue"

const MAPS_URL = "https://maps.googleapis.com/maps/api/js"
const MAPS_VERSION = "quarterly"
const API_KEY =
  process.env.VUE_APP_MAPS_API_KEY || "AIzaSyBjLKgZUTktUsiyd6JAMtrIHA8jkm_68aY"
const CALLBACK_NAME = "gmapsCallback"

let initialized = !!window.google
let resolveInitPromise
let rejectInitPromise
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve
  rejectInitPromise = reject
})

export function initialize() {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) {
    return initPromise
  }

  initialized = true
  // The callback function is called by the Google Maps script
  // if it is successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google)

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement("script")
  script.async = true
  script.defer = true

  script.src = `${MAPS_URL}?v=${MAPS_VERSION}&key=${API_KEY}&loading=async&libraries=marker,drawing&callback=${CALLBACK_NAME}`
  script.onerror = rejectInitPromise
  document.querySelector("head").appendChild(script)

  return initPromise
}

export function createNoDataToDisplayWindow(position, message) {
  const infoOptions = {
    position: position
  }
  const win = new google.maps.InfoWindow(infoOptions)

  win.setContent(message)

  return win
}

export function createInfoWindow(component, on, i18n) {
  if (typeof google !== undefined) {
    // We need to create this class after Google Maps have been initialized
    return new (class extends google.maps.OverlayView {
      constructor() {
        super()

        this.position = null
        this.visible = false
        this.props = null
        this.on = null
      }

      onAdd() {
        this.div = document.createElement("div")
        this.div.style.position = "absolute"
        this.div.style.transform = "translate(-50%, calc(-100% - 10px))" // Change also margin-top inside InfoWindow.vue
        this.div.style.width = "1000px" //With display: flex and pointer-events:none makes size fit content and pass mouse clicks to the map layer
        this.div.style.maxWidth = "calc(100% - 20px)"
        this.div.style.pointerEvents = "none" // Used to pass mouce clicks to the map (InfoWindow.vue should have pointer-events:all to make buttons work)
        this.div.style.display = "flex"
        this.div.style.justifyContent = "center"
        this.div.style.cursor = "initial"
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.div)

        const vmEl = document.createElement("div")
        this.div.appendChild(vmEl)

        // Create vue instance and mount at a new div
        this.vm = new Vue({
          i18n,
          el: vmEl,
          data: {
            visible: this.visible,
            props: this.props
          },
          render: function(h) {
            if (this.visible) {
              return h(component, {
                props: this.props,
                on: on
              })
            }

            return null
          }
        })

        const panes = this.getPanes()
        panes.floatPane.appendChild(this.div)
      }

      draw() {
        if (this.div && this.position) {
          const overlayProjection = this.getProjection()
          const position = overlayProjection.fromLatLngToDivPixel(this.position)

          this.div.style.left = `${position.x}px`
          this.div.style.top = `${position.y}px`
        }
      }

      onRemove() {
        if (this.vm) {
          this.vm.$destroy()
        }

        if (this.div) {
          this.div.parentNode.removeChild(this.div)
        }

        delete this.vm
        delete this.div
      }

      show(position, props) {
        this.position = position
        this.draw()

        this.visible = true
        this.props = props

        if (this.vm) {
          this.vm.$set(this.vm, "visible", true)
          this.vm.$set(this.vm, "props", props)

          // Pan to bounds when everything has been re-rendered
          this.vm.$nextTick().then(() => {
            const overlayProjection = this.getProjection()
            const position = overlayProjection.fromLatLngToDivPixel(
              this.position
            )

            // Convert overlay bounds to LatLngBounds
            const { width, height } = this.vm.$el.getBoundingClientRect()
            const swPoint = new google.maps.Point(
              position.x - width / 2,
              position.y
            )
            const nePoint = new google.maps.Point(
              position.x + width / 2,
              position.y - height
            )
            const sw = overlayProjection.fromDivPixelToLatLng(swPoint)
            const ne = overlayProjection.fromDivPixelToLatLng(nePoint)

            const bounds = new google.maps.LatLngBounds(sw, ne)
            this.getMap().panToBounds(bounds, {
              top: 40,
              left: 10,
              right: 10,
              bottom: 40
            })
          })
        }
      }

      hide() {
        this.position = null
        this.visible = false
        this.props = null
        this.on = null

        if (this.vm) {
          this.vm.$set(this.vm, "visible", false)
          this.vm.$set(this.vm, "props", null)
        }
      }
    })()
  }

  return null
}
