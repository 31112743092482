import { store } from "../store"
import { checkSessionTokens } from "./tokens"

const hasPermission = to => {
  switch (to.name) {
    case "users":
      return store.getters.canReadUsers
    case "tenant":
      return store.getters.canReadTenants
    case "maintenance":
      return store.getters.canReadMaintenance
    case "rental":
      return store.getters.canReadRental
    case "location":
      return store.getters.canReadGeolocation || store.getters.canReadGeofence
    case "dashboard":
    case "machines":
    case "events":
      return store.getters.canReadMachines
    case "measurements":
      return store.getters.canReadMeasurements
    case "parameters":
      return store.getters.canReadParameters
    case "system":
      return store.getters.canReadSystem
    case "reports":
      return store.getters.canReadReports
    case "fleetAnalytics":
      return store.getters.canReadFleetAnalytics
    default:
      return true
  }
}

const isAuthRoute = routePath =>
  routePath.indexOf("/login") !== -1 ||
  routePath.indexOf("/forgot_password") !== -1 ||
  routePath.indexOf("/verify") !== -1 ||
  routePath.indexOf("/reset") !== -1

// Note: only FE login valdation done here
const isLoggedIn = () => {
  return (
    store.getters.loggedInUser !== undefined &&
    store.getters.loggedInUser !== null &&
    localStorage.getItem("loggedInUser") !== null
  )
}

export default async (to, from, next) => {
  setTimeout(() => {
    // Check if there has been any JWT errors from the backend.
    var error = store.getters.error
    if (error !== null && error.causedBy.indexOf("token is expired") !== -1) {
      let loginParams = { path: "/login" }
      if (to.query.redirect) {
        //When redirected from e.g. service portal we need to leave redirect back after login
        loginParams.query = { redirect: to.query.redirect }
      }
      forceRelogin(() => {
        next(loginParams)
      })
    }
  }, 1000)

  if (!isLoggedIn() && !isAuthRoute(to.path)) {
    forceRelogin(() => {
      next({
        path: "/login",
        query: { redirect: to.path }
      })
    })
  } else {
    if (hasPermission(to)) {
      next()
    } else {
      next(from)
    }
  }
}

const forceRelogin = callback => {
  if (store.getters.loggedInUser == null) {
    callback()
    return
  }
  store.dispatch("logout")
  store.dispatch("setGenericDialog", {
    message: store.state.$i18n.t("login.sessionHasExpired"),
    icon: "mdi-account-circle",
    buttons: [
      {
        label: store.state.$i18n.t("common.actions.ok"),
        action: callback
      }
    ]
  })
}

// Check session at interval, force login if expired
var authCheckInterval = setInterval(() => {
  if (isLoggedIn()) {
    checkSessionTokens()
  } else {
    if (!isAuthRoute(window.location.href)) {
      clearInterval(authCheckInterval)
      forceRelogin(() => {
        window.location.href = window.location.origin
      })
    }
  }
}, 60000) // Every minute
