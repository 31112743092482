import fetchClient from "@/utils/fetchClient"
import countryList from "country-list"
import languages from "@/utils/languages"

const langCode = languages.getFromLocalStorage()
const langName = languages.getName(langCode)

export default {
  state: {
    loading: false,
    isSendingEmail: false,
    error: null,
    genericDialog: null,
    countries: countryList.getNames(),
    brokkEmail: "service@brokk.com",
    appTitle: "Brokk Connect",
    logoWhite: "/brokk-logo-wt.png",
    logoBlack: "/brokk-logo-bk.png",
    langCode: langCode,
    dataLangCode: "en",
    langName: langName,
    maintenanceThresholds: [
      // Engine hours before next service to send notification
      { text: "5 h", value: 5 },
      { text: "10 h", value: 10 },
      { text: "15 h", value: 15 },
      { text: "20 h", value: 20 },
      { text: "30 h", value: 30 },
      { text: "40 h", value: 40 }
    ],
    apiURL: process.env.VUE_APP_API_URL || "http://localhost:12100",
    webshopURL: process.env.VUE_APP_WEBSHOP_URL || "https://shop.my.brokk.com",
    serviceportalURL: process.env.VUE_APP_SERVICEPORTAL_URL || "http://localhost:8085",
    crmURL: process.env.VUE_APP_CRM_URL || "http://localhost:8069",
    webshopStartPage: process.env.VUE_APP_WEBSHOP_START_PAGE || "/orderportal"
  },
  mutations: {
    setLanguage(state, langCode) {
      state.langName = languages.getName(langCode)
      state.langCode = langCode
      languages.saveToLocalStorage(langCode)
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setSendingEmail(state, payload) {
      state.isSendingEmail = payload
    },
    setError(state, error) {
      let obj = {}

      if (error.response === undefined) {
        // No error message from the server.
        obj = {
          message: error.message,
          causedBy:
            error.causedBy !== undefined
              ? error.causedBy
              : this.state.$i18n.t(
                  "store.errors.couldNotConnectToBackendServer"
                )
        }
        if (error.url && error.url.length > 0) {
          obj.requestUri = error.url
        }
      } else if (error.response.data !== undefined) {
        obj = {
          message: error.response.data.message,
          causedBy: error.response.data.causedBy,
          details: error.message,
          requestMethod:
            error.response.data.requestMethod !== undefined
              ? error.response.data.requestMethod
              : null,
          requestUri:
            error.response.data.requestUri !== undefined
              ? error.response.data.requestUri
              : null
        }
      } else {
        obj = {
          message: error.message
        }
      }

      state.error = obj

      //console.error(JSON.stringify(obj))
    },
    clearError(state) {
      state.error = null
    },
    setGenericDialog(state, dialog) {
      let obj = {}

      if (dialog.message) {
        obj = {
          message: dialog.message,
          title: dialog.title !== undefined ? dialog.title : "Info",
          icon: dialog.icon !== undefined ? dialog.icon : "mdi-alert-circle",
          buttons: dialog.buttons !== undefined ? dialog.buttons : []
        }
      }

      state.genericDialog = obj
    },
    clearGenericDialog(state) {
      state.genericDialog = null
    }
  },
  actions: {
    setLanguage({ commit }, langCode) {
      commit("setLanguage", langCode)
    },
    setError({ commit }, err) {
      commit("setError", err)
    },
    clearError({ commit }) {
      commit("clearError")
    },
    setGenericDialog({ commit }, dialog) {
      commit("setGenericDialog", dialog)
    },
    sendEmailNotification(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setSendingEmail", true)

        fetchClient(this.state.$i18n)
          .post("/api/v1/notifications/email", params)
          .then(() => {
            resolve()
          })
          .catch(error => {
            const err = {
              message: this.state.$i18n.t(
                "store.errors.failedToSendEmailNotification"
              ),
              causedBy: error.toString()
            }
            context.commit("setError", err)
            reject(err)
          })
          .finally(() => {
            context.commit("setSendingEmail", false)
          })
      })
    }
  },
  getters: {
    loading(state) {
      return state.loading
    },
    isSendingEmail(state) {
      return state.isSendingEmail
    },
    error(state) {
      return state.error
    },
    genericDialog(state) {
      return state.genericDialog
    },
    countries: state => state.countries,
    brokkEmail: state => state.brokkEmail,
    appTitle: state => state.appTitle,
    logoWhite: state => state.logoWhite,
    logoBlack: state => state.logoBlack,
    langCode: state => state.langCode,
    dataLangCode: state => state.dataLangCode,
    langName: state => state.langName,
    maintenanceThresholds: state => state.maintenanceThresholds,
    apiURL: state => state.apiURL,
    webshopURL: state => state.webshopURL,
    serviceportalURL: state => state.serviceportalURL,
    crmURL: state => state.crmURL,
    webshopStartPage: state => state.webshopStartPage
  }
}
